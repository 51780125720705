import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { HeaderInterface } from './table.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {
  @Input() headers: HeaderInterface[] = [];
  @Input() totalRecords = 0;
  @Input() first = 0;
  @Input() rows = 5;
  @Input() loading = false;
  @Input() openSort = '';

  @Output() pageChange = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<void>();
  @Output() sortClick = new EventEmitter<void>();

  sortValue: any = ['ASC'];

  constructor() {
    // constructor
  }

  ngOnInit() {
    // lifecycle
  }

  onSortChange(event, sortName) {
    this.sortValue = event.filter(e => e === sortName);
  }

  onApplySort() {
    this.sortChange.emit(this.sortValue);
  }

  onPageChange(event) {
    this.first = event.first;
    this.rows = event.rows;

    this.pageChange.emit(event.page + 1);
  }

  onSortClick(value) {
    this.sortClick.emit(value);
  }
}
