import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() title = 'Modal';
  @Input() contentHeader = 'left';

  @Output() closeModal = new EventEmitter<void>();
  constructor() {
    // constructor
  }

  ngOnInit() {
    // lifecycle
  }

  onClose() {
    this.closeModal.emit();
  }
}
