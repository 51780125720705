import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environtment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {
    //
  }

  async loginRequest(payload: any) {
    const { email, password } = payload;

    return this.http
      .post<any>(
        environment.apiBaseUrl + '/auth/login',
        {
          email: email,
          password: password,
        },
        {
          headers: {
            TYPE: 'HazloAdmin',
          },
        },
      )
      .pipe(
        map(user => {
          if (typeof window !== 'undefined') {
            return user;
          }

          return user.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  async forgotPassword(payload: any) {
    const { email } = payload;

    return this.http
      .post<any>(
        environment.apiBaseUrl + '/auth/forgot-password',
        {
          email: email,
        },
        {
          headers: {
            TYPE: 'HazloAdmin',
          },
        },
      )
      .pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  async refreshToken(payload: any) {
    const { refreshToken, refreshTokenPatientService } = payload;

    return this.http
      .post<any>(
        environment.apiBaseUrl + '/auth/refresh-token',
        {
          refreshToken,
          refreshTokenPatientService,
        },
        {
          headers: {
            TYPE: 'HazloAdmin',
          },
        },
      )
      .pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  async changePassword(payload: any) {
    const { password, confirmPassword, token } = payload;

    return this.http
      .post<any>(
        environment.apiBaseUrl + '/auth/reset-password/' + token,
        {
          password,
          confirmPassword,
        },
        {
          headers: {
            TYPE: 'HazloAdmin',
            token,
          },
        },
      )
      .pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
