import { createReducer, on } from '@ngrx/store';
import { initialState } from './questionnaire.model';
import {
  loadQuestionnaire,
  loadQuestionnaireDetail,
  loadQuestionnaireDetailSuccess,
  loadQuestionnaireSuccess,
} from './questionnaire.action';

export const questionnaireReducer = createReducer(
  initialState,
  on(loadQuestionnaire, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadQuestionnaireSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadQuestionnaireDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadQuestionnaireDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
);
