import { createReducer, on } from '@ngrx/store';
import { initialState } from './header.model';
import {
  loadHeader,
  loadHeaderDetail,
  loadHeaderDetailSuccess,
  loadHeaderList,
  loadHeaderListSuccess,
  loadHeaderSuccess,
} from './header.action';

export const headerReducer = createReducer(
  initialState,
  on(loadHeader, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadHeaderSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadHeaderList, function (state) {
    return { ...state, error: null };
  }),
  on(loadHeaderListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, list: result.data };
  }),
  on(loadHeaderDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadHeaderDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
);
