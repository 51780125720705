import { createReducer, on } from '@ngrx/store';
import { initialState } from './partner.model';
import {
  loadPartner,
  loadPartnerDetail,
  loadPartnerDetailSuccess,
  loadPartnerList,
  loadPartnerListSuccess,
  loadPartnerSuccess,
} from './partner.action';

export const partnerReducer = createReducer(
  initialState,
  on(loadPartner, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadPartnerSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadPartnerList, function (state) {
    return { ...state, error: null };
  }),
  on(loadPartnerListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, list: result.data };
  }),
  on(loadPartnerDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadPartnerDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
);
