import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loginRequest, loginSuccess } from './auth.action';
import { from, map, switchMap } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/auth.service';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private authService: AuthService) {}

  // loginRequest$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(loginRequest),
  //     switchMap(() => from(this.authService.loginRequest()).pipe(map(auth => loginSuccess()))),
  //   );
  // });
}
