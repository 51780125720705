export const environment = {
  production: false,
  apiBaseUrl: 'https://hazlo-api-admin-dev.azurewebsites.net/api',
  blobUrl: 'https://hazloprofilestorage.blob.core.windows.net/images',
  apiPatientBaseUrl: 'https://hazlo-api-patient-dev.azurewebsites.net/api',
  patientBaseUrl: 'https://dev.patientportal.hazlohealth.com',
  tinyMceKey: '06dorxmfne69cp6fz27z63gn03peybnd9t0449p9kjyleb3r',
  sasKey:
    'sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2026-11-10T01:57:04Z&st=2023-11-09T17:57:04Z&spr=https&sig=WLyoeMhbnKnyqcsPUGqBW8VqX40INStAL3iYrJZ0n%2BA%3D',
};
