import { createReducer, on } from '@ngrx/store';
import { initialState } from './content-management.model';
import {
  loadContentManagement,
  loadContentManagementDetail,
  loadContentManagementDetailSuccess,
  loadContentManagementSuccess,
  loadMentalHealtList,
  loadMentalHealtListSuccess,
} from './content-management.action';

export const contentManagementReducer = createReducer(
  initialState,
  on(loadContentManagement, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadContentManagementSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadContentManagementDetail, function () {
    return { ...initialState, status: 'loading-detail', error: null };
  }),
  on(loadContentManagementDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
  on(loadMentalHealtList, function (state) {
    return { ...state, error: null };
  }),
  on(loadMentalHealtListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, mentalHealtList: result.data };
  }),
);
