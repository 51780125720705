/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import {
  loadPatient,
  loadPatientDetail,
  loadPatientDetailSuccess,
  loadPatientList,
  loadPatientListSuccess,
  loadPatientSuccess,
} from './patient.action';
import { PatientState } from './patient.model';

export const initialState: PatientState = {
  data: [],
  list: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};

export const patientReducer = createReducer(
  initialState,
  on(loadPatient, state => ({ ...state, status: 'loading', error: null })),
  on(loadPatientSuccess, (state, { data }) => {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadPatientDetail, state => ({ ...state, status: 'loading-detail', error: null })),
  on(loadPatientDetailSuccess, (state, { data }) => {
    return { ...state, detail: data.data, status: 'success' };
  }),
  on(loadPatientList, function (state) {
    return { ...state, error: null };
  }),
  on(loadPatientListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, list: result.data };
  }),
);
