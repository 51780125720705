import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/partner/partner.module').then(module => module.PartnerModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/patient/patient.module').then(module => module.PatientModule),
  },
  {
    path: 'questionnaire',
    loadChildren: () =>
      import('./modules/questionnaire/questionnaire.module').then(module => module.QuestionnaireModule),
  },
  {
    path: 'categories-and-interest',
    loadChildren: () =>
      import('./modules/categories-interest/categories-interest.module').then(
        module => module.CategoriesInterestModule,
      ),
  },
  {
    path: 'headers',
    loadChildren: () => import('./modules/headers/headers.module').then(module => module.HeadersModule),
  },
  {
    path: 'content-management',
    loadChildren: () =>
      import('./modules/content-management/content-management.module').then(
        module => module.ContentManagementModule,
      ),
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
