import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private modalReloginSource = new BehaviorSubject<any>(null);
  modalRelogin$ = this.modalReloginSource.asObservable();

  constructor(private http: HttpClient) {
    //
  }

  updateMyProperty(value: any) {
    this.modalReloginSource.next(value);
  }
}
