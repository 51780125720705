import { createAction, props } from '@ngrx/store';

export const loadInterest = createAction('[Interest] Load Interest', props<{ query: any }>());
export const loadInterestSuccess = createAction('[Interest] Load Interest Success', props<{ data: any[] }>());

export const loadInterestList = createAction('[Interest] Load Interest Group List', props<{ query: any }>());
export const loadInterestListSuccess = createAction(
  '[Interest] Load Interest Group List Success',
  props<{ data: any[] }>(),
);

export const loadInterestListInterest = createAction('[Interest] Load Interest List', props<{ id: any }>());
export const loadInterestListInterestSuccess = createAction(
  '[Interest] Load Interest List Success',
  props<{ data: any[] }>(),
);

export const loadInterestDetail = createAction('[Interest] Load Interest Detail', props<{ data: any }>());
export const loadInterestDetailSuccess = createAction(
  '[Interest] Load Interest Detail Success',
  props<{ data: any }>(),
);
