import { createAction, props } from '@ngrx/store';

export const loadHeader = createAction('[Header] Load Header', props<{ query: any }>());
export const loadHeaderSuccess = createAction('[Header] Load Header Success', props<{ data: any[] }>());

export const loadHeaderList = createAction('[Header] Load Header List', props<{ query: any }>());
export const loadHeaderListSuccess = createAction('[Header] Load Header List Success', props<{ data: any[] }>());

export const loadHeaderDetail = createAction('[Header] Load Header Detail', props<{ data: any }>());
export const loadHeaderDetailSuccess = createAction('[Header] Load Header Detail Success', props<{ data: any }>());
