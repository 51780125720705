import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationInterface } from './sidebar.interface';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/states/auth/auth.reducer';
import { logoutRequest } from 'src/app/states/auth/auth.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  path: any = '';
  navigations: NavigationInterface[] = [
    {
      name: 'Partner',
      icon: 'building',
      path: '/',
      subPath: [''],
      queryParams: {},
    },
    {
      name: 'Patients',
      icon: 'patients',
      path: '/patients',
      subPath: ['patients'],
      queryParams: {},
    },
    {
      name: 'Questionnaire',
      icon: 'questionnaire',
      path: '/questionnaire',
      subPath: [
        'questionnaire',
        'questionnaire?language=Spanish',
        'questionnaire?language=English',
        'create-questionnaire-English',
        'create-questionnaire-Spanish',
      ],
      queryParams: { language: 'Spanish' },
    },
    {
      name: 'Category and interest',
      icon: 'category-interest',
      path: '/categories-and-interest',
      subPath: [
        'categories-and-interest',
        'categories-and-interest?language=Spanish',
        'categories-and-interest?language=English',
        'create-categories-interest-English',
        'create-categories-interest-Spanish',
      ],
      queryParams: { language: 'Spanish' },
    },
    {
      name: 'Headers',
      icon: 'headers',
      path: '/headers',
      subPath: [
        'headers',
        'headers?language=English',
        'headers?language=Spanish',
        'create-headers-English',
        'create-headers-Spanish',
      ],
      queryParams: { language: 'Spanish' },
    },
    {
      name: 'Content Management',
      icon: 'book',
      path: '/content-management',
      subPath: [
        'content-management',
        'content-management?language=Spanish',
        'content-management?language=English',
        'create-content-management-English',
        'create-content-management-Spanish',
        'edit-content-management',
      ],
      queryParams: { language: 'Spanish' },
    },
  ];

  constructor(private location: Location, private store: Store<{ auth: AuthState }>, private router: Router) {}

  ngOnInit() {
    const pathArray = this.location.path().split('/');
    const path = pathArray.pop();

    this.path = this.isValidUUID(path) ? pathArray[pathArray.length - 2] : path;
  }

  logout() {
    localStorage.clear();
    this.store.dispatch(logoutRequest());

    this.router.navigate(['/login']);
  }

  isValidUUID(string: string) {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(string);
  }
}
