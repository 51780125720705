<div class="card flex justify-content-center">
  <p-dialog (onHide)="onClose()" [header]="title" [(visible)]="showModal" [style]="{ width: '50vw' }">
    <ng-template *ngIf="contentHeader === 'center'" pTemplate="header">
        <div class="w-full flex justify-center">
          <p class="text-xl font-bold text-center">{{ title }}</p>
        </div>
    </ng-template>
    <ng-content></ng-content>
  </p-dialog>
</div>
