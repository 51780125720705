<div class="sidebar h-full flex flex-col">
  <div class="flex-1">
    <div class="panel">
      <div *ngFor="let navigation of navigations; index as i" class="item">
        <div [hidden]="!navigation.subPath.includes(path)" class="absolute left-0">
          <div class="green-linier w-3 rounded-r-md h-[43px] -mt-2"></div>
        </div>
        <a [routerLink]="navigation.path" [queryParams]="navigation.queryParams" class="duration-300 hover:scale-110 ml-4">
          <img
            src="/assets/images/icons/{{ navigation.icon }}.svg"
            class="h-6 w-6"
            alt="hazlo-{{ navigation.name }}"
          />
        </a>
      </div>
    </div>
  </div>
  <div class="flex-1 items-end cursor-pointer w-full flex justify-center  pb-10">
    <div (click)="logout()">
      <i class="pi pi-sign-out duration-300 hover:scale-110" style="font-size: 1rem; color: black; font-weight: bold"></i>
    </div>
  </div>
</div>
