import { createAction, props } from '@ngrx/store';

export const loadPartner = createAction('[Partner] Load Partner', props<{ query: any }>());
export const loadPartnerSuccess = createAction('[Partner] Load Partner Success', props<{ data: any[] }>());

export const loadPartnerList = createAction('[Partner] Load Partner List');
export const loadPartnerListSuccess = createAction(
  '[Partner] Load Partner List Success',
  props<{ data: any[] }>(),
);

export const loadPartnerDetail = createAction('[Partner] Load Partner Detail', props<{ data: any }>());
export const loadPartnerDetailSuccess = createAction(
  '[Partner] Load Partner Detail Success',
  props<{ data: any }>(),
);
