import { createAction, props } from '@ngrx/store';

export const loadPatient = createAction('[Patient] Load Patient', props<{ query: any }>());
export const impersonatePatient = createAction('[Patient] Impersonate Patient', props<{ query: any }>());
export const loadPatientSuccess = createAction('[Patient] Load Patient Success', props<{ data: any[] }>());

export const loadPatientList = createAction('[Patient] Load Patient List', props<{ query: any }>());
export const loadPatientListSuccess = createAction(
  '[Patient] Load Patient List Success',
  props<{ data: any[] }>(),
);

export const loadPatientDetail = createAction('[Patient] Load Patient Detail', props<{ data: any }>());
export const loadPatientDetailSuccess = createAction(
  '[Patient] Load Patient Detail Success',
  props<{ data: any }>(),
);
