import { createAction, props } from '@ngrx/store';

export const loadQuestionnaire = createAction('[Questionnaire] Load Questionnaire', props<{ query: any }>());
export const loadQuestionnaireSuccess = createAction(
  '[Questionnaire] Load Questionnaire Success',
  props<{ data: any[] }>(),
);

export const loadQuestionnaireList = createAction('[Questionnaire] Load Questionnaire List');
export const loadQuestionnaireListSuccess = createAction(
  '[Questionnaire] Load Questionnaire List Success',
  props<{ data: any[] }>(),
);

export const loadQuestionnaireDetail = createAction(
  '[Questionnaire] Load Questionnaire Detail',
  props<{ data: any }>(),
);
export const loadQuestionnaireDetailSuccess = createAction(
  '[Questionnaire] Load Questionnaire Detail Success',
  props<{ data: any }>(),
);
