import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserRole } from '../commons/fixture/user/userType';
import { environment } from 'environtment/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('token');
      const tokenPatientService = localStorage.getItem('tokenPatientService');
      if (token) {
        request = request.clone({
          setHeaders: {
            TYPE: UserRole.ADMIN,
            Authorization: `Bearer ${token}`,
          },
        });
        if (request.url.includes(environment.apiPatientBaseUrl))
          request = request.clone({
            setHeaders: {
              TYPE: UserRole.ADMIN,
              Authorization: `Bearer ${tokenPatientService}`,
            },
          });
      }
    }

    return next.handle(request);
  }
}

export const JwtInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};
