export interface DataContentManagementInterface {
  id: string;
  headerId: string;
  content: string;
  title: string;
  description: string;
  language: string;
  status: string;
  publishDate: string;
  dateType: string;
  numberOfDays: number;
  scheduleStart: string;
  scheduleEnd: string;
  typeOfContent: string;
  mediaUrl: string;
  quote: string;
  body: IBodyForm[];
  header: IHeader;
  interest: IInterest[];
  conditions: IConditions[];
  dateDiff: number;
  category: ICategory;
}

export interface ICategory {
  groupId: string;
  groupName: string;
}

export interface ContentManagementState {
  data: DataContentManagementInterface[];
  mentalHealtList: any[];
  detail: DataContentManagementInterface | null;
  error: string | null;
  status: string;
  meta: any;
}

export interface IInterest {
  id: string;
  interest: {
    interestName: string;
  };
}

export interface IConditions {
  id: string;
  name: string;
}

export interface IHeader {
  id: string;
  name: string;
}

export interface IBodyListForm {
  name: string;
  order: number;
}

export interface IBodyForm {
  type: string;
  label: string;
  keys: string;
  index: number;
  options: IBodyListForm[];
}

export const initialState: ContentManagementState = {
  data: [],
  mentalHealtList: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
