export interface DataInterestInterface {
  groupId: string;
  groupName: string;
  sequence: number;
  status: string;
  language: string;
  createdAt: string;
  publishAt: string;
  publishedAt: string;
  updatedAt: string;
  interestCount: string;
  published: boolean;
}

export interface InterestState {
  data: DataInterestInterface[];
  list: any[];
  listInterest: any[];
  detail: DataInterestInterface | null;
  error: string | null;
  status: string;
  meta: any;
}

export interface IBodyListForm {
  name: string;
}

export interface IBodyForm {
  type: string;
  label: string;
  keys: string;
  index: number;
  list: IBodyListForm[];
}

export interface IForm {
  groupName: string;
  description: string;
  status: string;
  language: string;
  body: IBodyForm[];
}

export const initialState: InterestState = {
  data: [],
  list: [],
  listInterest: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
