import { createReducer, on } from '@ngrx/store';
import { initialState } from './interest.model';
import {
  loadInterest,
  loadInterestDetail,
  loadInterestDetailSuccess,
  loadInterestList,
  loadInterestListInterest,
  loadInterestListInterestSuccess,
  loadInterestListSuccess,
  loadInterestSuccess,
} from './interest.action';

export const interestReducer = createReducer(
  initialState,
  on(loadInterest, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadInterestSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data.items, meta: result.data.meta, status: 'success' };
  }),
  on(loadInterestDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadInterestDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
  on(loadInterestList, function (state) {
    return { ...state, error: null };
  }),
  on(loadInterestListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, list: result.data };
  }),
  on(loadInterestListInterest, function (state) {
    return { ...state, error: null };
  }),
  on(loadInterestListInterestSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, listInterest: result.data };
  }),
);
