/* eslint-disable @ngrx/no-typed-global-store */
import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthState } from './states/auth/auth.reducer';
import { selectAuthProperty } from './states/auth/auth.selector';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { loginSuccess, logoutRequest } from './states/auth/auth.action';
import { tap } from 'rxjs';
import { AppService } from './app.service';
import { AuthService } from './modules/auth/auth.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  path: any = '';
  modalRelogin = false;
  idTimeout;

  public auth$: AuthState;

  constructor(
    private location: Location,
    private store: Store<{ auth: AuthState }>,
    private router: Router,
    private appService: AppService,
    private authService: AuthService,
    private userIdle: UserIdleService,
  ) {
    this.appService.modalRelogin$.subscribe(value => {
      this.modalRelogin = value;
    });

    router.events.subscribe((val: any) => {
      if (val.url !== undefined && val.url !== '/login') {
        this.userIdle.startWatching();
        this.userIdle.onTimerStart().subscribe();

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
          this.openModalRelogin();
          this.userIdle.stopTimer();
        });
      }
    });

    const token = localStorage.getItem('token');
    if (token) {
      this.store.dispatch(loginSuccess({ param: { token } }));
    }
  }

  ngOnInit() {
    const authList = ['login', 'reset-password', 'create-new-password'];

    const pathArray = this.location.path().split('/');

    if (pathArray.some(element => element.includes('create-new-password'))) {
      this.path = pathArray[pathArray.length - 2];
    } else {
      this.path = pathArray.pop();
    }

    this.store
      .select(selectAuthProperty)
      .pipe(
        tap(data => {
          this.auth$ = data;
          if ((this.path === '' || !authList.some(element => element.includes(this.path))) && !data.isLogin) {
            this.router.navigate(['/login']);
          }
        }),
      )
      .subscribe();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    this.userIdle.stopTimer();
  }

  openModalRelogin() {
    if (this.location.path() !== '/login') {
      this.idTimeout = setTimeout(() => {
        this.logout();
      }, 1000 * 2 * 60);

      this.appService.updateMyProperty(true);
    }
  }

  closeModalRelogin() {
    this.appService.updateMyProperty(false);
  }

  logout() {
    this.appService.updateMyProperty(false);

    clearTimeout(this.idTimeout);
    localStorage.clear();

    this.store.dispatch(logoutRequest());
    this.router.navigate(['/login']);
  }

  async continueLogin() {
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshTokenPatientService = localStorage.getItem('refreshTokenPatientService');

    (await this.authService.refreshToken({ refreshToken, refreshTokenPatientService })).subscribe(
      async data => {
        localStorage.setItem('token', data.data.accessToken);
        localStorage.setItem('refreshToken', data.data.refreshToken);
        localStorage.setItem('tokenPatientService', data.data.accessTokenPatientService);

        window.location.reload();
      },
      error => {
        this.router.navigate(['/login']);
      },
    );
  }
}
