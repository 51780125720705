import { NgModule } from '@angular/core';
import { BaseComponent } from '../layout/base/base.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { TableComponent } from './components/table/table.component';
import { ModalComponent } from './components/modal/modal.component';
import { RouterLink } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [SidebarComponent, BaseComponent, TableComponent, ModalComponent],
  imports: [
    DialogModule,
    PaginatorModule,
    DropdownModule,
    CommonModule,
    DividerModule,
    RouterLink,
    CheckboxModule,
  ],
  exports: [SidebarComponent, BaseComponent, TableComponent, ModalComponent],
})
export class CoreModule {}
