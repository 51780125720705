export interface DataQuestionnaireInterface {
  id: string;
  title: string;
  status: string;
  language: string;
  createdAt: string;
  publishAt: string;
  publishDate: string;
  updatedAt: string;
}

export interface QuestionnaireState {
  data: DataQuestionnaireInterface[];
  detail: DataQuestionnaireInterface | null;
  error: string | null;
  status: string;
  meta: any;
}

export interface IBodyListForm {
  name: string;
}

export interface IBodyForm {
  type: string;
  label: string;
  keys: string;
  index: number;
  list: IBodyListForm[];
}

export interface IForm {
  title: string;
  description: string;
  status: string;
  language: string;
  body: IBodyForm[];
}

export const initialState: QuestionnaireState = {
  data: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
