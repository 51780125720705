<router-outlet *ngIf="auth$.status !== 'loading'"></router-outlet>
<div [hidden]="auth$.status !== 'loading'">
    <div class="flex h-screen w-full justify-center items-center">
        <img src="/assets/images/logo.png" />
    </div>
</div>

<app-modal [showModal]="modalRelogin" [title]="'Your Account is Expired'" (closeModal)="closeModalRelogin()" [contentHeader]="'center'">
    <div class="p-5">
        <div class="mt-2">
            <p>Your login session will expire in 2 mins due to inactivity. Do you want to say signed in?</p>
        </div>

        <div class="w-full flex justify-center mt-10 gap-5">
            <button type="submit" (click)="logout()" class="p-3 px-6 rounded-md text-black bg-white border border-[#A9A9A9]">
              <p class="text-base font-normal">Logout</p>
            </button>
            <button type="submit" (click)="continueLogin()" class="p-3 px-6 rounded-md text-white bg-[#2A97AD]">
              <p class="text-base font-normal">Continue</p>
            </button>
          </div>
    </div>
</app-modal>
  