import { createReducer, on } from '@ngrx/store';
import { loginFailed, loginRequest, loginSuccess, logoutRequest } from './auth.action';

export interface AuthState {
  isLogin: boolean;
  error: string;
  status: 'pending' | 'loading' | 'error' | 'success';
}

export const initialState: AuthState = {
  isLogin: false,
  error: '',
  status: 'pending',
};

export const authReducer = createReducer(
  initialState,
  on(loginRequest, state => ({
    ...state,
    status: 'loading',
  })),
  on(loginSuccess, (state, { param }) => ({
    ...state,
    isLogin: true,
    status: 'success',
  })),
  on(logoutRequest, state => ({
    ...state,
    isLogin: false,
    status: 'success',
  })),
  on(loginFailed, state => ({
    ...state,
    isLogin: false,
    status: 'success',
  })),
);
