<div class="-mt-3 overflow-x-scroll">
  <table aria-describedby="hazlo-tabel" class="list-table">
    <thead>
      <tr>
        <th
          *ngFor="let header of headers; index as i"
          [ngStyle]="
            header.align !== 'center'
              ? { 'text-align': 'left', 'padding-left': header.align === 'left' ? '30px' : '0px', 'width': header.width ?? '' }
              : { 'text-align': 'center', 'width': header.width ?? '' }
          "
        >
         <div [ngClass]="header.align !== 'center' ? 'justify-start' : 'justify-center'" class="flex">
            {{ header.name }}
          <div class="ml-3 relative" *ngIf="header.isSort">
              <button (click)="onSortClick(header.sortKey)">
                <img src="/assets/images/icons/sort.svg" alt="hazlo" />
              </button>
              <div *ngIf="openSort === header.sortKey" class="py-5 px-10 w-[241px] border border-[E3E5E5] mt-5 rounded-md absolute right-0 bg-[#F9FDFF]">
                <p>Filter by</p>
                <div class="mt-3 grid grid-cols-1 gap-y-5">
                  <div>
                    <p-checkbox class="checkbox-filter" name="sort" [(ngModel)]="sortValue" value="ASC" (ngModelChange)="onSortChange($event, 'ASC')" label="A-Z 1-9"></p-checkbox>
                  </div>
                  <div>
                    <p-checkbox class="checkbox-filter" name="sort" [(ngModel)]="sortValue" value="DESC" (ngModelChange)="onSortChange($event, 'DESC')" label="Z-A 9-1"></p-checkbox>
                  </div>
                </div>
                <div class="flex gap-5 mt-5">
                  <button (click)="onSortClick(header.sortKey)" class="p-2 px-4 rounded-md text-black bg-gray-200">
                    <p class="text-base font-normal">Cancel</p>
                  </button>

                  <button (click)="onApplySort()" class="p-2 px-4 rounded-md text-white bg-[#2B97AD]">
                    <p class="text-base font-normal">Apply</p>
                  </button>
                </div>
              </div>
          </div>
         </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td [colSpan]="headers.length">
          <p>Loading...</p>
        </td>
      </tr>
      <tr *ngIf="!loading && totalRecords === 0">
        <td [colSpan]="headers.length">
          <p>No data available</p>
        </td>
      </tr>
      <ng-content *ngIf="!loading && totalRecords > 0"></ng-content>
    </tbody>
  </table>
</div>
<div>
  <div class="flex align-items-center justify-end">
    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="first"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [showFirstLastIcon]="false"
      [showCurrentPageReport]="false"
      currentPageReportTemplate="{first} - {last} of {totalRecords}"
    ></p-paginator>
  </div>
</div>
