export interface DataPartnerInterface {
  id: string;
  organizationName: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  createdAt: string;
  totalPatients: number;
  status: string;
  identifier: string;
  einNumber: string;
}

export interface PartnerState {
  data: DataPartnerInterface[];
  detail: DataPartnerInterface | null;
  list: any[];
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: PartnerState = {
  data: [],
  list: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
