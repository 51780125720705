import { createAction, props } from '@ngrx/store';

export const loadContentManagement = createAction(
  '[ContentManagement] Load ContentManagement',
  props<{ query: any }>(),
);
export const loadContentManagementSuccess = createAction(
  '[ContentManagement] Load ContentManagement Success',
  props<{ data: any[] }>(),
);

export const loadContentManagementList = createAction('[ContentManagement] Load ContentManagement List');
export const loadContentManagementListSuccess = createAction(
  '[ContentManagement] Load ContentManagement List Success',
  props<{ data: any[] }>(),
);

export const loadContentManagementDetail = createAction(
  '[ContentManagement] Load ContentManagement Detail',
  props<{ data: any }>(),
);
export const loadContentManagementDetailSuccess = createAction(
  '[ContentManagement] Load ContentManagement Detail Success',
  props<{ data: any }>(),
);

export const loadMentalHealtList = createAction('[MentalHealt] Load MentalHealt List');
export const loadMentalHealtListSuccess = createAction(
  '[MentalHealt] Load MentalHealt List Success',
  props<{ data: any[] }>(),
);
