<div class="w-full h-screen flex flex-col base">
  <!-- HEADER -->
  <div class="py-1 green-linier">
    <div class="pl-5">
      <img src="/assets/images/logo-white.png" alt="hazlo" class="h-[7vh]" />
    </div>
  </div>
  <div class="flex flex-1 content-min ">
    <app-sidebar></app-sidebar>
    <p-divider layout="vertical"></p-divider>
    <ng-content></ng-content>
  </div>
</div>
