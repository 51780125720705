import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducer } from './states/auth/auth.reducer';
import { AuthEffects } from './states/auth/auth.effects';
import { questionnaireReducer } from './states/questionnaire/questionnaire.reducer';
import { patientReducer } from './states/patients/patient.reducer';
import { partnerReducer } from './states/partner/partner.reducer';
import { JwtInterceptorProvider } from './core/helpers/jwt.interceptor';
import { headerReducer } from './states/header/header.reducer';
import { interestReducer } from './states/interest/interest.reducer';
import { contentManagementReducer } from './states/content-management/content-management.reducer';
import { HttpErrorInterceptorProvider } from './core/helpers/errorInterceptor';
import { provideUserIdleConfig } from 'angular-user-idle';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule.forRoot(),
    StoreModule.forRoot({
      auth: authReducer,
      patient: patientReducer,
      questionnaire: questionnaireReducer,
      partner: partnerReducer,
      header: headerReducer,
      interest: interestReducer,
      contentManagement: contentManagementReducer,
    }),
    EffectsModule.forRoot([AuthEffects]),
  ],
  providers: [
    JwtInterceptorProvider,
    HttpErrorInterceptorProvider,
    provideUserIdleConfig({ idle: 5, timeout: 60 * 13, ping: 120 }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
